import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import AccountNigrationModalSpinner from './components/AccountNigrationModalSpinner/';

import { formatErrorMessage } from '../../../../../../localization/message-formatting';
import AccountMigrationModal from '../AccountMigrationModal';
import Spacer from '../../../../../layout/Spacer';
import { Link } from 'react-bootstrap/lib/Navbar';

const validationSchema = yup.object().shape({
  password: yup.string().required('password.required'),
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'invalid request': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.',
  },
  unknown: {
    id: 'migration.error.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
  'Invalid OTP.': {
    id: 'accountMigrationForgotPasswordModal.error.incorrectPassword',
    defaultMessage: 'Incorrect one-time password',
  },
  'Retry attempts exceeded.': {
    id: 'accountMigrationForgotPasswordModal.error.retryExceeded',
    defaultMessage: 'Retry attempts exceeded.',
  },
};

const AccountMigrationForgotPasswordModalForm = ({
  intl,
  onSubmit,
  isOpen,
  onClose,
  isLoading,
  handleResendClick,
  error,
  clearError,
}) => {
  if (!isOpen) return null;
  return (
    <AccountMigrationModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialized={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <h2 className="account-migration-modal-form-description">
              <FormattedMessage
                id="accountMigrationForgotPasswordModal.subheading"
                defaultMessage="Enter your one-time password."
              />
            </h2>
            <div className="inputComponent">
              <FormattedMessage id="accountMigrationForgotPasswordModal.input.label" defaultMessage="Password">
                {placeholder => (
                  <Input
                    placeholder={placeholder}
                    type="password"
                    name="password"
                    onChange={e => {
                      handleChange(e);
                      clearError();
                    }}
                    onBlur={e => {
                      handleBlur(e);
                      clearError();
                    }}
                    value={values.password}
                    error={
                      errors.password && touched.password
                        ? formatErrorMessage(intl, errorMessages, errors.password)
                        : error
                        ? formatErrorMessage(intl, errorMessages, error)
                        : null
                    }
                  />
                )}
              </FormattedMessage>
            </div>
            <Spacer y={24} />
            <div className="account-migration-modal-footer-otp">
              <div className="account-migration-modal-footer-otp-text-wrapper">
                <FormattedMessage
                  id="accountMigrationForgotPasswordModal.didntReceive"
                  defaultMessage="Didn't receive email?"
                />
                <div className="account-migration-modal-link-wrapper resend">
                  <Link onClick={handleResendClick}>Resend</Link>
                </div>
              </div>
              <Spacer y={24} />
              <div className="account-migration-modal-footer">
                <Button
                  id="accountMigrationModal.form.transfer"
                  className="button--secondary"
                  type="submit"
                  disabled={isLoading}
                >
                  <div className="account-migration-modal-button-text">
                    <FormattedMessage id="accountMigrationModal.form.transfer" defaultMessage="Transfer" />
                    <AccountNigrationModalSpinner isLoading={isLoading} />
                  </div>
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </AccountMigrationModal>
  );
};

AccountMigrationForgotPasswordModalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  translationObject: PropTypes.object,
  handleResendClick: PropTypes.func,
  error: PropTypes.string,
  clearError: PropTypes.func,
};

export default injectIntl(AccountMigrationForgotPasswordModalForm);
