import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'jpi-cloud-web-ui-components';

import { getUserInfo, getUserSystems } from '../../AppInitializer/actions';
import { removeSystemTiles, unsetSystemAsPrepopulated } from '../../tiles/TileManager/actions';
import {
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
} from './actions';

import ProfileTab from './components/ProfileTab/ProfileTab';
import AppearanceTab from './components/AppearanceTab/AppearanceTab';
import NotificationSettingsTab from './components/NotificationSettingsTab/NotificationSettingsTab';
import AccountSecurityTab from './components/AccountSecurityTab/AccountSecurityTab';
import DeleteAccountTab from './components/DeleteAccountTab/DeleteAccountTab';

import ThirdPartyApps from './components/ThirdPartyApps/ThirdPartyApps';

import { tabNames } from '../../constants/constants';

import './profilePage.scss';

const ProfilePage = ({
  userInfo,
  profilePage,
  profileTab,
  getUserInfo,
  getUserSystems,
  removeSystemTiles,
  unsetSystemAsPrepopulated,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
  history,
  match,
}) => {
  const [state, setState] = useState({
    isLoaded: false,
    isEmailEditShown: false,
    isEmailConfirmationSent: false,
    isAddressEditShown: false,
    isPasswordChanged: false,
    selectedTab: 'profile',
    deletionToken: '',
  });

  const getClassName = tabName =>
    classNames(state.selectedTab === tabName ? 'option-label selected' : 'option-label', isDisabledClassName);

  const tabs = [
    { id: tabNames.PROFILE, labelId: 'label.profile', defaultLabel: 'Profile' },
    { id: tabNames.APPEARANCE, labelId: 'label.appearance', defaultLabel: 'Appearance' },
    { id: tabNames.NOTIFICATION_SETTINGS, labelId: 'label.notification-settings', defaultLabel: 'Notifications' },
    { id: tabNames.PERMISSIONS, labelId: 'label.permissions', defaultLabel: 'Permissions' },
    { id: tabNames.ACCOUNT_SECURITY, labelId: 'label.account-security', defaultLabel: 'Account Security' },
    { id: tabNames.DELETE, labelId: 'label.delete', defaultLabel: 'Remove Account' },
  ];

  const selectTab = tab => {
    const isDemo = userInfo && userInfo.isDemo;
    if (isDemo && (tab === 'email' || tab === 'password' || tab === 'delete')) {
      return false;
    }

    history.push(`/profile-settings/${tab}`);
  };

  const init = async () => {
    if (state.isLoaded) {
      setState(prevState => ({ ...prevState, isLoaded: false }));
    }

    handleRedirectionAfterPasswordChange();
    handleDeletionToken();
    await getUserInfo();

    setState(prevState => ({ ...prevState, isLoaded: true }));
  };

  useEffect(() => {
    const { tab = tabNames.PROFILE } = match.params;

    if (!Object.values(tabNames).includes(tab)) return selectTab(tabNames.PROFILE);

    setState(prevState => ({ ...prevState, selectedTab: tab }));
  }, [match.params]);

  useEffect(() => {
    init();
  }, []);

  const getCurrentTab = () => {
    switch (state.selectedTab) {
      case tabNames.PROFILE:
        return <ProfileTab userInfo={userInfo} requestError={profileTab.profileError} addressId={userInfo.addressId} />;
      case tabNames.APPEARANCE:
        return <AppearanceTab />;
      case tabNames.NOTIFICATION_SETTINGS:
        return <NotificationSettingsTab />;
      case tabNames.ACCOUNT_SECURITY:
        return <AccountSecurityTab />;
      case tabNames.PERMISSIONS:
        return <ThirdPartyApps />;
      case tabNames.DELETE:
        return (
          <DeleteAccountTab
            deleteError={profilePage.deleteError}
            deleteUser={deleteAccount}
            getUserSystems={getUserSystems}
            resetDeleteError={resetDeleteError}
            removeSystemTiles={removeSystemTiles}
            unsetSystemAsPrepopulated={unsetSystemAsPrepopulated}
            userId={userInfo.id}
            userEmail={userInfo.email}
            history={history}
            validatePasswordError={profilePage.validatePasswordError}
            validateUserCredentials={validateUserCredentials}
            deletionToken={state.deletionToken}
          />
        );
      default:
        return null;
    }
  };

  const handleRedirectionAfterPasswordChange = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    if (code) {
      issueAzureB2CTokensAfterPasswordChangeAction(code);
    }
  };

  const handleDeletionToken = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    if (token) {
      setState(prevState => ({
        ...prevState,
        selectedTab: 'delete',
        deletionToken: token,
      }));
    }
  };

  if (!state.isLoaded) {
    return <Spinner />;
  }

  const isDemo = userInfo && userInfo.isDemo;
  const isDisabledClassName = isDemo ? 'disabled' : '';

  return (
    <div className="page-content">
      <div className="profile-page">
        <div className="tabordion">
          <section className="profile-section" id="profile-section">
            {tabs.map(tab => (
              <div key={tab.id} onClick={() => selectTab(tab.id)} className={getClassName(tab.id)}>
                <FormattedMessage id={tab.labelId} defaultMessage={tab.defaultLabel} />
              </div>
            ))}
          </section>
          <div className="tab-content">{getCurrentTab()}</div>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  removeSystemTiles: PropTypes.func.isRequired,
  unsetSystemAsPrepopulated: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  profilePage: PropTypes.object,
  userInfo: PropTypes.object,
  validateUserCredentials: PropTypes.func.isRequired,
  profileTab: PropTypes.object,
  issueAzureB2CTokensAfterPasswordChangeAction: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = ({ app: { userInfo }, profilePage, profileTab }) => ({
  userInfo,
  profilePage,
  profileTab,
});

const mapDispatchToProps = {
  getUserInfo,
  getUserSystems,
  removeSystemTiles,
  unsetSystemAsPrepopulated,
  deleteAccount,
  resetDeleteError,
  validateUserCredentials,
  issueAzureB2CTokensAfterPasswordChangeAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
