import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import classNames from 'classnames';

import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { defaultMaxLength, userNameRegex, address, noRegion } from '../../../../constants/constants';

import AddressTab from '../AddressTab/AddressTab';
import AccountMigration from '../AccountMigration';

const profileSchema = yup.object().shape({
  tzId: yup.string().trim().nullable(),
  fullName: yup
    .string()
    .trim()
    .required('full-name.required')
    .max(defaultMaxLength, 'full-name.max-length')
    .matches(userNameRegex, 'full-name.invalid-format'),
  country: yup.object().required('country.required').nullable(),
  city: yup
    .string()
    .trim()
    .required('city.required')
    .matches(address.pattern, 'city.invalid-format')
    .max(address.cityNameMaxLength, 'city.max-length'),
  lineOne: yup
    .string()
    .trim()
    .required('address.line-one.required')
    .matches(address.pattern, 'address.line-one.invalid-format')
    .max(defaultMaxLength, 'address.line-one.max-length'),
  lineTwo: yup
    .string()
    .trim()
    .matches(address.pattern, 'address.line-two.invalid-format')
    .max(defaultMaxLength, 'address.line-two.max-length'),
  region: yup.string().when('country', {
    is: country => country && country.regions && country.regions.length > 0,
    then: yup
      .string()
      .required('region.required')
      .max(address.regionNameMaxLength, 'region.maxlength')
      .matches(address.pattern, 'region.invalid-format'),
    otherwise: yup
      .string()
      .max(address.regionNameMaxLength, 'region.maxlength')
      .matches(address.pattern, 'region.invalid-format'),
  }),
  postalCode: yup
    .string()
    .trim()
    .required('postal-code.required')
    .max(address.postalCode.maxLength, 'postal-code.maxlength')
    .matches(address.postalCode.regex, 'postal-code.regexp'),
});

const errorMessages = {
  'tzId.required': {
    id: 'profile.error.validation.time-zone.required',
    defaultMessage: 'Time zone is mandatory field',
  },
  'full-name.required': {
    id: 'full-name.error.validation.required',
    defaultMessage: 'Full name is mandatory field',
  },
  'full-name.invalid-format': {
    id: 'full-name.error.validation.invalid-format',
    defaultMessage: 'Full name has invalid format',
  },
  'full-name.max-length': {
    id: 'full-name.error.validation.maxlength',
    defaultMessage: 'Full name cannot be longer than 255 characters',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'Country is mandatory field',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is required',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
};

const defaultAddress = {
  country: null,
  region: '',
  city: '',
  postalCode: '',
  lineOne: '',
  lineTwo: '',
};

const EditProfileInfo = ({ userInfo, timeZones, onSubmit, intl, addressId, fullAddress, isValueSaved }) => {
  const address =
    fullAddress && fullAddress.country && fullAddress.country.regions.length > 0
      ? { ...fullAddress, country: { ...fullAddress.country, regions: [noRegion, ...fullAddress.country.regions] } }
      : fullAddress;
  const editedAddress = { ...defaultAddress, ...address, id: addressId };
  const { isDemo, canMigrateUplinkAccount, email, userSite } = userInfo || {};
  const isDisabledClassName = isDemo ? 'disabled' : '';

  const uTzId = timeZones ? timeZones.find(x => x.id === userInfo.tzId) : '';

  return (
    <div className="editProfileInfo">
      <Formik
        initialValues={{
          fullName: userInfo.fullName ? userInfo.fullName : '',
          tzId: uTzId ? uTzId : '',
          addressId: userInfo.addressId ? userInfo.addressId : '',
          ...editedAddress,
        }}
        validationSchema={profileSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit} className="form--half">
            <input type="hidden" value={values.addressId} name="addressId" />
            <div className="field-row">
              <Input
                type="text"
                name="fullName"
                placeholder={intl.formatMessage({ id: 'label.fullName' })}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullName}
                error={errors.fullName && touched.fullName && formatErrorMessage(intl, errorMessages, errors.fullName)}
              />
            </div>
            <hr />

            <AddressTab
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              address={fullAddress}
              addressId={addressId}
            />
            <div className="button-wrapper">
              <Button
                className={classNames('button--secondary', isDisabledClassName)}
                type="submit"
                disabled={isSubmitting}
              >
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
            </div>
            {isValueSaved && (
              <div className="success-label">
                <FormattedMessage id="savedMessage" defaultMessage="Saved!" />
              </div>
            )}
          </form>
        )}
      </Formik>
      {canMigrateUplinkAccount && <AccountMigration email={email} userSite={userSite} />}
    </div>
  );
};

EditProfileInfo.propTypes = {
  userInfo: PropTypes.object,
  timeZones: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
  addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullAddress: PropTypes.object.isRequired,
  isValueSaved: PropTypes.bool.isRequired,
};

export default injectIntl(EditProfileInfo);
