import React, { useState } from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from 'jpi-cloud-web-ui-components';

import PasswordValidationForm from '../../PasswordValidationForm';
import EditEmailForm, { emailSchema } from '../../EmailTab/EditEmailForm';

import { changeEmail } from '../../../actions';
import { NotificationManager } from 'react-notifications';

const EmailSection = ({ userInfo, changeEmail }) => {
  const [newEmail, setNewEmail] = useState('');
  const [showPswdForm, setShowPswdForm] = useState(false);
  const [pswdError, setPswdError] = useState(false);

  const initiateEmailChange = data => {
    setNewEmail(data.newEmail);
    setShowPswdForm(true);
  };

  const handleSubmit = async password => {
    const emailChangeSuccess = await changeEmail(userInfo.id, userInfo.email.trim(), newEmail.trim(), password.trim());

    if (emailChangeSuccess.error) {
      return setPswdError(emailChangeSuccess.error);
    }

    NotificationManager.success(
      <FormattedMessage
        id="email-tab.confirmation"
        defaultMessage="We have sent an email to confirm your change to the new address."
      />,
      null,
      5000,
    );

    setShowPswdForm(false);
  };

  const handleCancel = () => {
    setShowPswdForm(false);
    setPswdError('');
  };

  const isDisabledClassName = userInfo.isDemo ? 'disabled' : '';

  const initialValues = {
    oldEmail: userInfo.email,
    newEmail,
  };

  return (
    <div className="emailSection">
      <p>
        <FormattedMessage id="common.email" defaultMessage="Email" />
      </p>
      <br />

      <Formik initialValues={initialValues} validationSchema={emailSchema} onSubmit={initiateEmailChange}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit} className="form--half">
            <EditEmailForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isPendingNewEmail={newEmail !== ''}
              userInfo={userInfo}
            />

            <div className="button-wrapper">
              <Button
                type="submit"
                className={classNames('button--secondary', isDisabledClassName)}
                disabled={(showPswdForm && isSubmitting) || !isValid}
              >
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
            </div>
          </form>
        )}
      </Formik>

      {showPswdForm && <PasswordValidationForm onSubmit={handleSubmit} onCancel={handleCancel} pswdError={pswdError} />}
    </div>
  );
};

EmailSection.propTypes = {
  userInfo: PropTypes.object,
  changeEmail: PropTypes.func.isRequired,
};

const stateProps = ({ app: { userInfo } }) => ({ userInfo });

export default connect(stateProps, { changeEmail })(EmailSection);
